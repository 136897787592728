








import Vue from "vue";
import Component from "vue-class-component";

import Terms from "@/components/Terms.vue";

@Component({
  components: {
    Terms,
  }
})
export default class Home extends Vue {}
